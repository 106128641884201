<template>
  <div>
    <div class="wrong-box">
      <div class="wrong">
        <div class="wrong-title">
          <div class="title-l">
            <span class="short">单词速记</span>
            >
            <span class="cTest">我的错词</span>
          </div>
          <div class="title-r"><el-button icon=" el-icon-refresh-left" type="success" circle plain style="margin-right: 10px;" @click="refresh" />
            <span class="record">错词记录</span>
            <span class="return" @click="backStudy">返回学习中心</span>
          </div>
        </div>
        <div class="wrong-container">
          <div class="wrong-l">
            <ul class="wrong-list">
              <li
                :class="['list-item', index == left_index ? 'chooseItem' : '']"
                v-for="(item, index) in left_nav"
                :key="index"
                @click="clkLeftNav(index,item)"
                @mouseenter="removeAnimate('.detail-list')"
              >
                <span class="item-info">{{ item.name }}</span>
                <span class="item-num">（{{ item.count }}）</span>
              </li>
              <!--<li class="list-item">智能词听</li>
                            <li class="list-item">智能听写</li>
                            <li class="list-item">智能默写</li>
                            <li class="list-item">例句听组</li>
                            <li class="list-item">例句听力</li>
                            <li class="list-item">例句翻译</li>
                            <li class="list-item">例句默写</li>-->
            </ul>
          </div>
          <div class="wrong-r">
            <div class="wrong-r__array">
              <el-radio v-model="sort" label="1">时间顺序</el-radio>
              <el-radio v-model="sort" label="0">字母顺序</el-radio>
              <div class="array-word__num">
                <span>每页单词数：</span>
                <el-select v-model="chooseItem" @change="changeOrder">
                  <el-option label="1个" value="1"></el-option>
                  <el-option label="3个" value="3"></el-option>
                  <el-option label="6个" value="6"></el-option>
                  <el-option label="12个" value="12"></el-option>
                </el-select>
              </div>
              <el-button @click="regain">重新获取</el-button>
              <el-button @click="export_cuoci">导出错词</el-button>
            </div>
            <div class="wrong-r__content">
              <div class="content-info">
                <div class="content-btn">
                  <div
                    :class="[
                      'content-btn__item',
                      top_tab == 0 ? 'chooseItem' : '',
                    ]"
                    @click="xuexiDo"
                  >
                    <span>单词学习</span>
                    <span>{{left_nav[left_index].xuexi_count}}</span>
                  </div>
                  <div
                    :class="[
                      'content-btn__item',
                      top_tab == 1 ? 'chooseItem' : '',
                    ]"
                    @click="ceshiDo"
                  >
                    <span>单词测试</span>
                    <span>{{left_nav[left_index].error_count}}</span>
                  </div>
                </div>
                <div class="content-detail">
                  <ul class="detail-list">
                    <template v-for="(item, index) in lists">
                      <li
                        class="detail-list__container memoryBox"
                        :key="index"
                        @click="convers(index,item)"
                      >
                        <div class="memoryContainer" ref="detail">
                          <div
                            :class="[
                              'detail-list__container--item',
                              item.level === 2 ? 'yellow' : '',
                            ]"
                          >
                            <span class="item-word">{{ item.name }}</span>
                          </div>
                          <div
                            :class="[
                              'detail-list__container--item chinese',
                              item.level === 2 ? 'yellow' : '',
                            ]"
                          >
                            <span class="item-word">{{ item.analysis }}</span>
                          </div>
                        </div>
                        <i
                          class="fa fa-volume-up"
                          @click="audioPlay_3(item.audio_link, item.id)"
                        ></i>
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="content-footer">
                  <div class="footer-page">
                    <div class="p-info">
                      第<span class="p-num">{{ page }}</span
                      >/ <span class="p-total">{{ allPage }}</span
                      >页，共 <span class="p-data">{{ size }}</span
                      >条数据
                    </div>
                    <!--<el-pagination
                                          background
                                          prev-text=" 上一页"
                                          next-text="下一页 "
                                          layout="prev, pager, next"
                                          :total="1"
                                        ></el-pagination>-->
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :total="size"
                      :hide-on-single-page="false"
                      :page-size="pageSize"
                      :current-page="page"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="智能记忆"
      width="1000px"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :close-on-click-modal="close"
      :show-close="close"
    >
      <aiRemeber
        @close="xuexiClose"
        title="hello!"
        :audio-url="audioUrl"
        :words-lists="xuexi_list"
        :study-type="study_type"
        ref="dialog_jiyi"
      />
    </el-dialog>
    <el-dialog
      title="智能词听"
      width="1000px"
      :visible.sync="ciVisible"
      v-if="ciVisible"
      :close-on-click-modal="close"
      :show-close="close"
    >
      <aiListen
        @close="xuexiClose"
        :audio-url="audioUrl"
        :words-lists="xuexi_list"
        :study-type="study_type"
        ref="dialog_citing"
      />
    </el-dialog>
    <el-dialog
      title="智能听写"
      width="1000px"
      :visible.sync="writeVisible"
      v-if="writeVisible"
      :close-on-click-modal="close"
      :show-close="close"
    >
      <aiWrite
        @close="xuexiClose"
        :audio-url="audioUrl"
        :words-lists="xuexi_list"
        :study-type="study_type"
        ref="dialog_tingxie"
      />
    </el-dialog>
    <el-dialog
      title="智能默写"
      width="1000px"
      :visible.sync="diVisible"
      v-if="diVisible"
      :close-on-click-modal="close"
      :show-close="close"
    >
      <aiDictation
        @close="xuexiClose"
        :audio-url="audioUrl"
        :words-lists="xuexi_list"
        :study-type="study_type"
        ref="dialog_moxie"
      />
    </el-dialog>

    <el-dialog
      class="unitTest"
      fullscreen
      :visible.sync="reTestVisible"
      v-if="reTestVisible"
      width="914px"
    >
      <reTest
        @close="ceshiClose"
        @cuociGonggu="cuociGonggu"
        :test-data="test_data"
        :test-type="test_type"
        :test-type-name="test_type_name[test_type - 1]"
        ref="dialog_jiyi_ceshi"
      />
    </el-dialog>

    <el-dialog
      class="unitTest"
      fullscreen
      :visible.sync="liTestVisible"
      v-if="liTestVisible"
      width="914px"
    >
      <liTest
        @close="ceshiClose"
        @cuociGonggu="cuociGonggu"
        :audio-url="audioUrl"
        :test-data="test_data"
        :test-type="test_type"
        :test-type-name="test_type_name[test_type - 1]"
        ref="dialog_citing_ceshi"
      />
    </el-dialog>

    <el-dialog
      class="unitTest"
      fullscreen
      :visible.sync="wrTestVisible"
      v-if="wrTestVisible"
      width="914px"
    >
      <wrTest
        @close="ceshiClose"
        @cuociGonggu="cuociGonggu"
        :audio-url="audioUrl"
        :test-data="test_data"
        :test-type="test_type"
        :test-type-name="test_type_name[test_type - 1]"
        ref="dialog_tingxie_ceshi"
      />
    </el-dialog>

    <el-dialog
      class="unitTest"
      fullscreen
      :visible.sync="diTestVisible"
      v-if="diTestVisible"
      width="914px"
    >
      <diTest
        @close="ceshiClose"
        @cuociGonggu="cuociGonggu"
        :audio-url="audioUrl"
        :test-data="test_data"
        :test-type="test_type"
        :test-type-name="test_type_name[test_type - 1]"
        ref="dialog_moxie_ceshi"
      />
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import { mapMutations, mapState } from "vuex";
import aiRemeber from "@/components/ai_word_components/ai_remeber"; //单词学习---记忆模块
import aiListen from "@/components/ai_word_components/ai_listen"; //单词学习---词听模块
import aiWrite from "@/components/ai_word_components/ai_write"; //单词学习---听写模块
import aiDictation from "@/components/ai_word_components/ai_dictation"; //单词学习---默写模块

import reTest from "@/components/ai_word_components/re_unit_test"; //记忆测试
import liTest from "@/components/ai_word_components/li_unit_test"; //词听测试
import wrTest from "@/components/ai_word_components/wr_unit_test"; //听写测试
import diTest from "@/components/ai_word_components/di_unit_test"; //默写测试
export default {
    inject: ['reload'],
  data() {
    return {
        domBool: true,
      dialogVisible: false, // 记忆模块
      ciVisible: false, // 词听模块
      writeVisible: false, // 听写模块
      diVisible: false, // 默写模块

      reTestVisible: false, //记忆测试
      liTestVisible: false, //词听测试
      wrTestVisible: false, //听写测试
      diTestVisible: false, //默写测试
      audioUrl: "http://manage.xmy.xyz", //音频域名地址
      test_type: 5, //"今日已学测试", "已学测试", "单元测试", "全词测试",'错词测试'
      test_type_name: [
        "今日已学测试",
        "已学测试",
        "单元测试",
        "全词测试",
        "错词测试",
      ],
      currentWords: [],
      study_type: 3, //类型，1学习，2复习，3错词
      chooseItem: "12",
      wrongVisible: false,
      close: false,
      left_nav: [{id: 1, name: "智能翻译", count: 0, xuexi_count: 0, error_count: 0},
                    {id: 2, name: "智能听选", count: 0, xuexi_count: 0, error_count: 0},
                    {id: 3, name: "智能听写", count: 0, xuexi_count: 0, error_count: 0},
                    {id: 4, name: "智能默写", count: 0, xuexi_count: 0, error_count: 0}
                ], //"智能记忆", "智能词听", "智能听写", "智能默写","例句听组","例句听力","例句翻译","例句默写",
      left_index: 0,
      type: 1,
      page: 1,
      pageSize: 12,
      sort: "1",
      size: 0,
      audio_obj: [],
      top_tab: 0,
      lists: [],
      dialog_num: [0, 0, 0, 0],
      word_ids: [],
      test_data: {},
      all_lists: [],
      xuexi_list:[],
    };
  },
  components: {
    aiRemeber,
    aiListen,
    aiWrite,
    aiDictation,
    reTest,
    wrTest,
    liTest,
    diTest,
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    ceshiNum() {
      let num = 0;
      this.all_lists.forEach((item, index) => {
        if (item.level == 2) {
          num++;
        }
      });
      return num;
    },
    xuexiNum() {
      let num = 0;
      this.all_lists.forEach((item, index) => {
        if (item.level == 1) {
          num++;
        }
      });
      return num;
    },
    allPage() {
      return Math.ceil(this.size / this.pageSize);
    },
    wordIdsStr() {
      let str = "";
      let i = 0;
      this.all_lists.forEach((item, index) => {
        if(i<15){
          if (item.level == 2) {
            str += item.id + ",";
            i++;
          }
        }
      });
      if (str.length > 0) {
        //str = str.slice(0, str.length - 1);
      }
      return str;
    },
    xuexiList(){
        let xuexi_lists = [];
        let i = 0;
        this.all_lists.forEach((item, index) => {
          if(i<10){
            if (item.level == 1) {
              xuexi_lists.push(item);
              i++;
            }
          }
        });
        return xuexi_lists;
    },
  },
  created() {
    this.getWords();
    this.getAllWords();
  },
  updated() {
    setTimeout(this.conversInit, 10);
  },
  methods: {
        refresh() {
            this.reload()
        },
    fadeIns(arg) {
      let dContent = document.querySelector(arg);
      dContent.classList.add("fadeIns");
      if(this.domBool){
        dContent.classList.add("fadeIns");
      }
    },
    removeAnimate(arg) {
      let dContent = document.querySelector(arg);
      dContent.classList.remove("fadeIns");
      if(this.domBool){
        dContent.classList.remove("fadeIns");
      }
    },
    changeOrder(num) {
      this.pageSize = parseInt(num);
      console.log(num);
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getWords();
    },
    clkLeftNav(index,item) {
      this.fadeIns(".detail-list");
      this.sort = "1";
      this.page = 1;
      this.left_index = index;
      this.type = item.id;
      //console.log(this.test_nav);
      this.getWords();
      this.getAllWords();
      // this.conversInit();
    },
    getWords() {
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        type: this.type,
        page: this.page,
        pageSize: this.pageSize,
        sort: this.sort,
      };
      console.log(params);
      this.axios
        .post("/api/course/get_error_word_index", params)
        .then((res) => {
          //console.log(res);
          var data = res.data;
          if (data.status_code == 200) {
            this.lists = res.data.data.lists;
            //this.all_lists = res.data.data.all_lists;
            this.size = res.data.data.size;
            this.left_nav = res.data.data.nav;
          } else if (res.data.status_code == 202) {
          } else {
            //this.$message(res.data.msg)
          }
        })
        .catch((err) => {
          //this.loading = false
        });
    },
    getAllWords() {
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        type: this.type,
        page: this.page,
        pageSize: 10,
        sort: this.sort,
      };
      console.log(params);
      this.axios
        .post("/api/course/get_error_word_all", params)
        .then((res) => {
          //console.log(res);
          var data = res.data;
          if (data.status_code == 200) {
            this.all_lists = res.data.data.all_lists;
          } else if (res.data.status_code == 202) {
          } else {
            //this.$message(res.data.msg)
          }
        })
        .catch((err) => {
          //this.loading = false
        });
    },
    getTestWords() {
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        type: this.type,
        word_ids: this.wordIdsStr,
        test_type: this.test_type,
      };
      console.log(params);
      this.axios
        .post("/api/test_center/get_word", params,{
					loading:true
				})
        .then((res) => {
          //console.log(res);
          var data = res.data;
          if (data.status_code == 200) {
            this.test_data = data.data;
            //console.log("test_data");
            //console.log(this.test_data);
            than.showCeshiDialog();
          } else if (res.data.status_code == 202) {
          } else {
            //this.$message(res.data.msg)
          }
        })
        .catch((err) => {
          //this.loading = false
        });
    },
    regain() {
      this.page = 1;
      this.getWords();
      this.getAllWords();
    },
    audioPlay_3(audioLink, id) {
      try {
        console.log("播放音频id" + id);
        console.log("播放音频" + this.audioUrl + audioLink);
        var that = this;
        var audio = that.audio_obj[id];
        if (!audio) {
          audio = new Audio();
          that.audio_obj[id] = audio;
        }
        // console.log(audio)
        that.audio_obj[id] = audio;
        audio.src = this.audioUrl + audioLink;
        audio.play(); //播放
        //监听播放结束
        audio.addEventListener(
          "ended",
          function () {
            that.audio_obj[id] = null;
          },
          false
        );
      } catch (err) {
        console.log("播放音频失败");
      }
    },
    ceshiDo() {
      //console.log(this.ceshi_lists);
      this.top_tab = 1;
      //this.showCeshiDialog();
      if (this.ceshiNum > 0) {
        this.getTestWords();
      }
    },
    xuexiDo() {
      this.top_tab = 0;
      if (this.xuexiNum > 0) {
        this.xuexi_list = this.xuexiList;
        this.showXuexiDialog();
      }
      //console.log(this.xuexi_lists);
    },
    showXuexiDialog() {
      switch (this.type) {
        case 1:
          this.dialogVisible = true;
          /*if (this.dialog_num[0] > 0) {
            this.$refs.dialog_jiyi.initData();
            console.log("this.dialog_num" + this.dialog_num[0]);
          } else {
            console.log("this.dialog_num[0]" + this.dialog_num[0]);
          }
          this.dialog_num[0]++;
          //console.log("dialogJiyi");
          //this.xuexi_jilu_index = index;*/
          break;
        case 2:
          this.ciVisible = true;
          /*if (this.dialog_num[1] > 0) this.$refs.dialog_citing.initData();
          this.dialog_num[1]++;
          //console.log("dialogJiyi");
          //this.xuexi_jilu_index = index;*/
          break;
        case 3:
          this.writeVisible = true;
          /*if (this.dialog_num[2] > 0) this.$refs.dialog_tingxie.initData();
          this.dialog_num[2]++;
          //console.log("dialogJiyi");
          //this.xuexi_jilu_index = index;*/
          break;
        case 4:
          this.diVisible = true;
          /*if (this.dialog_num[3] > 0) this.$refs.dialog_moxie.initData();
          this.dialog_num[3]++;
          //console.log("dialogJiyi");
          // this.xuexi_jilu_index = index;*/
          break;
        default:
          break;
      }
    },
    xuexiClose(id, is_close = 0) {
      switch (id) {
        case 1:
          this.$refs.dialog_jiyi.closeDialog();
          break;
        case 2:
          this.$refs.dialog_citing.closeDialog();
          break;
        case 3:
          console.log("dialog_tingxie");
          this.$refs.dialog_tingxie.closeDialog();
          break;
        case 4:
          console.log("dialog_moxie");
          this.$refs.dialog_moxie.closeDialog();
          break;
        case 5:
          break;
      }
      if (is_close == 1) {
        //this.getDanyuan(1);
        this.dialogVisible = false;
        this.ciVisible = false;
        this.writeVisible = false;
        this.diVisible = false;
        this.rootVisible = false;
      }
      this.getWords();
      this.getAllWords();
    },
    showCeshiDialog() {
      //显示弹窗
      let test_data = this.test_data;
      console.log(test_data);
      if (this.left_index == 0) {
        this.reTestVisible = true;
        //this.$refs.dialog_jiyi_ceshi.initData(test_data);
      } else if (this.left_index == 1) {
        this.liTestVisible = true; //词听
        //this.$refs.dialog_citing_ceshi.initData(test_data);
      } else if (this.left_index == 2) {
        this.wrTestVisible = true; //听写
        //this.$refs.dialog_tingxie_ceshi.initData(test_data);
      } else if (this.left_index == 3) {
        this.diTestVisible = true; //默写
        //this.$refs.dialog_moxie_ceshi.initData(test_data);
      }
    },
    ceshiClose() {
      //关闭弹窗
      this.reTestVisible = false;
      this.liTestVisible = false; //词听
      this.wrTestVisible = false; //听写
      this.diTestVisible = false; //默写
      this.getWords();
      this.getAllWords();
    },
    getWordsCuociGonggu(words_ids) {
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        type: this.type,
        words_ids: words_ids,
        study_type: this.study_type,
        words_ids: words_ids,
      };
      console.log(params);
      this.axios
        .post("/api/course/get_word", params)
        .then((res) => {
          //console.log(res);
          var data = res.data;
          if (data.status_code == 200) {
            this.xuexi_list = data.data.lists;
            this.showCuociGongguDialog();
          } else if (res.data.status_code == 202) {
          } else {
            //this.$message(res.data.msg)
          }
        })
        .catch((err) => {
          //this.loading = false
        });
    },
    cuociGonggu(errorLists) {
        console.log("errorLists", errorLists);
       this.getWordsCuociGonggu(errorLists);
    },
    showCuociGongguDialog() {
      //显示弹窗
      if (this.type == 1) {
        this.dialogVisible = true;
      } else if (this.type == 2) {
        this.ciVisible = true; //词听
      } else if (this.type == 3) {
        this.writeVisible = true; //听写
      } else if (this.type == 4) {
        this.diVisible = true; //默写
      }
    },
    shuffle(arr) {
      arr.sort(() => Math.random - 0.5);
    },
    backStudy() {
      //返回学习中心
      this.$router.go(-1); //返回上一层
    },
    conversInit() {
      $(".memoryContainer").removeClass("convers");
    },
    convers(index,item) {
      let memoryBox = document.querySelectorAll(".memoryBox>div:first-child");
      // console.log(memoryBox[index].classList.contains('convers'));
      memoryBox[index].classList.toggle("convers");

      this.audioPlay_3(item.audio_link,item.id);
    },
    export_cuoci(){
      console.log('export_cuoci');
      // 获取token
	    let token = window.sessionStorage.getItem('token')
	    //console.log("token:"+token);
      window.location.href = '/api/course/export_cuoci?token='+token;
    }
  },
  beforeUpdate: function () {
    $(".memoryBox")
      .mouseenter(function () {
        let vIndex = $(".memoryBox").index(this);
        $(".memoryBox .fa").eq(vIndex).css("display", "block");
      })
      .mouseleave(function () {
        let lIndex = $(".memoryBox").index(this);
        $(".memoryBox .fa").eq(lIndex).css("display", "none");
      });
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/word_wrong.scss";

.yellow {
  background-color: #f59527 !important;
}
</style>
